<template>
<form @submit.prevent="FormType == 'add' ? addDriver() : updateDriver()">
    <div class="driver-form">
        <div class="p-fluid">
            <div class="p-field p-grid">
                <label for="driver_name" class="p-col-fixed">Driver name*:</label>
                <div class="p-col">
                    <InputText id="driver_name" v-model="driverForm.driver_name" type="text" :class="{'p-invalid':v$.driver_name.$error}" />
                    <small v-if="v$.driver_name.$error" class="p-error">{{v$.driver_name.required.$message.replace('Value', 'Driver name')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="sex" class="p-col-fixed">Gender*:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedGender" @change="onGenderChange()" :options="driver_gender" optionLabel="sex" placeholder="Select gender" :class="{'p-invalid':v$.sex.$error}" />
                    <small v-if="v$.sex.$error" class="p-error">{{v$.sex.required.$message.replace('Value', 'Sex')}}</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="transportation_company" class="p-col-fixed">Company*:</label>
                <div class="p-col">
                    <Dropdown v-model="selectedTransportation" @change="onTransportationChange()" :options="transportation_company" optionLabel="company_name" placeholder="Transport company" :class="{'p-invalid':isSelectedTransportCompany}" />
                    <small v-if="isSelectedTransportCompany" class="p-error">Transport company is required</small>
                </div>
            </div>
            <div class="p-field p-grid">
                <label for="phone" class="p-col-fixed">Phone*:</label>
                <div class="p-col">
                    <InputMask v-model="driverForm.phone" mask="999 999-999?9" :class="{'p-invalid':v$.phone.$error}"/>
                    <small v-if="v$.phone.$error" class="p-error">{{v$.phone.required.$message.replace('Value', 'Phone number')}}</small>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-fixed">
                <div v-if="FormType == 'add'">
                    <Button v-if="can('create', 'driver')" label="Save" icon="pi pi-save" type="submit" class="p-button-success" />
                </div>
                <div v-else>
                    <Button v-if="can('update', 'driver')" label="Update" icon="pi pi-save" type="submit" class="p-button-warning" />
                </div>
            </div>
            <div class="p-col-fixed">
                <InlineMessage v-if="messages.isMsgShow" :severity="messages.severity" class="p-ml-4">{{ messages.content }}</InlineMessage>
            </div>	
        </div>
    </div>
</form>
</template>

<script>
import { ref, reactive, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import DriverService from '../service/DriverService';

export default {
    props: {
        FormType: String
    },
    emits: ['RefreshDriverList'],
    setup(props, { emit }) {
        const { can } = useAbility()
        const store = useStore()
        const toast = useToast()
        let driverForm = reactive({})
        let driver_info = reactive({}) //Get data from store to fill in form
        let driver_id = ref(0) //Save driver id to use in update form

        const driver_gender = [{sex: 'F'}, {sex: 'M'}]
        let selectedGender = ref()

        const transportation_company = ref([])
        let selectedTransportation = ref()
        let isSelectedTransportCompany = ref(false)

        let messages =  computed(() => store.state.showMsg)

        //On Create
        const driverService = ref(new DriverService())

        onMounted(() => {
            initFormData()

            if(props.FormType == 'update') {
                driver_info = store.state.driverInfo.driverUpdateForm
                
                driver_id.value = driver_info.id
                selectedGender.value = {sex: driver_info.sex}
                setTransportationDropdown('update-form', driver_info.transportation_id)
                Object.assign(driverForm, driver_info)
            } else {
                setTransportationDropdown()
            }
        })

        const initFormData = () => {
            const initForm = {
                driver_name: '',
                sex: '',
                phone: '',
                transportation_id: null
            }

            selectedGender.value = ''
            selectedTransportation.value = ''
            Object.assign(driverForm, initForm)
        }

        const setTransportationDropdown = (formType, transportationId) => {
            driverService.value.getAllCompanyName().then(data => {
                transportation_company.value = data

                if (formType == 'update-form'){
                    const companyName = transportation_company.value.filter(item => item.id == transportationId)
                    selectedTransportation.value = companyName[0]
                } else {
                    selectedTransportation.value = ''
                }
            });
        }

        const addDriver = () => {
            if(validateForm()) {
                driverService.value.addDriver(driverForm).then((data) => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Add Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        initFormData();
                        emit('RefreshDriverList')
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully added.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const updateDriver = () => {
            if(validateForm()) {
                driverService.value.updateDriver(driver_id.value, driverForm).then((data) => {
                    if(data.errorResponse) {
                        toast.add({severity:'warn', summary: 'Update Data Error ' + data.status, detail: data.errorResponse, life: 5000});        
                    } else {
                        emit('RefreshDriverList')
                        toast.add({severity:'success', summary: 'Info', detail:'Data successfully updated.', life: 3000});
                        v$.value.$reset() //Reset validations
                    }
                })
            }
        }

        const onGenderChange = () => {
            driverForm.sex = selectedGender.value.sex;
        }

        const onTransportationChange = () => {
            driverForm.transportation_id = selectedTransportation.value.id;
            isSelectedTransportCompany.value = false
        }

        //Form Validations
        const rules = computed(() => {
            return {
                driver_name: { required },
                sex: { required },
                phone: { required },
            }            
        })
        const v$ = useVuelidate(rules, driverForm)
        const validateForm = () => {
            v$.value.$validate();
            if(!v$.value.$error && selectedTransportation.value.company_name){
                isSelectedTransportCompany.value = false
                return true
            } else {
                isSelectedTransportCompany.value = true
                return false
            }
        }

        return {
            can,
            v$,
            driverForm,
            driver_gender,
            selectedGender,
            onGenderChange,
            transportation_company,
            selectedTransportation,
            onTransportationChange,
            isSelectedTransportCompany,

            addDriver,
            updateDriver,

            messages,
        }
    },
}
</script>

<style lang="scss" scoped>
.driver-form {
    margin-top: 5px;

    label {
        width: 110px;
    }
}
</style>