<template>
<div class="driver-list">
    <div class="search-bar">
        <div class="p-input-icon-left search-field">
            <i class="pi pi-search" />
            <InputText type="text" placeholder="Search by name" v-model="filters['global'].value" />
        </div>
        <Button v-if="can('create', 'driver')" label="Add" @click="openDriverFormDialog('add')" class="p-button-sm" icon="pi pi-plus" />
    </div>

    <div class="filter-bar">
        <div class="p-fluid p-grid p-field">
            <label for="role_name" class="p-col-fixed">Company:</label>
            <div class="p-col">
                <Dropdown v-model="selectedTransCompanyFilter" :options="filterTransCompanyDropdown" optionLabel="company_name">
                </Dropdown> 
            </div>

            <div class="p-col-fixed filter-buttons">
                <Button label="Filter" @click="filterTransCompany()" class="p-mr-1 p-button-sm p-button-warning filter-button" icon="pi pi-filter" />
                <Button label="Clear" @click="clearFilter()" class="p-button-sm p-button-secondary filter-button" icon="pi pi-filter-slash" />
            </div>
        </div>
    </div>
    
    <div class="content-section implementation">
        <div class="card">
            <DataTable
                :value="drivers"
                responsiveLayout="scroll"
                scrollHeight="600px"
                v-model:selection="selectedDriver" 
                selectionMode="single"
                v-model:filters="filters"
                :globalFilterFields="['driver_name']"
                dataKey="id"
            >
                <!-- <Column field="index" header="No." :headerStyle="{'max-width':'90px','min-width':'50px'}" :bodyStyle="{'max-width':'90px','min-width':'50px'}"> -->
                <Column field="id" header="ID." > </Column>
                <Column field="driver_name" header="Name" :style="{'min-width':'100px'}"></Column>
                <Column field="sex" header="Gender" :style="{'min-width':'50px'}"></Column>
                <Column field="company_name" header="Company" :style="{'min-width':'180px'}"></Column>
                <Column field="phone" header="Phone" :style="{'min-width':'120px'}"></Column>
                
                <Column v-if="can('update', 'driver') || can('delete', 'driver')" :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.7rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button v-if="can('update', 'driver')" icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="openDriverFormDialog('update', slotProps.index)"/>
                        <Button v-if="can('delete', 'driver')" icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteDriver(slotProps.data)" />
                    </template>
                </Column>

            </DataTable>
            <ConfirmDialog></ConfirmDialog>
        </div>
    </div>

    <Dialog v-model:visible="showDriverFormDialog" :style="{width: '45rem', margin: '0px 5px'}" :modal="true">
        <template #header>
            <h5 style="margin: 0px">{{dialogHeader}}</h5>
        </template>
        <DriverForm :FormType="form_type" @RefreshDriverList="getDriverData()" />
    </Dialog>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useAbility } from '@casl/vue';
import { FilterMatchMode } from 'primevue/api';
import DriverForm from '../components/DriverForm.vue'

import DriverService from '../service/DriverService';

export default {
    setup() {
        const { can } = useAbility()
        const confirm = useConfirm()
        const store = useStore()
        const toast = useToast()

        let drivers = ref([])
        let selectedDriver = ref()
        const filters = ref({
            'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        });

        let dialogHeader = ref('')
        let form_type = ref('')
        const showDriverFormDialog = ref(false)

        let filterTransCompanyDropdown = ref([])
        const selectedTransCompanyFilter = ref()

        //On Created        
        const driverService = ref(new DriverService());
        
        onMounted(() => {
            getDriverData()
            setFilterTransCompanyDropdown()
        })

        //Method
        const getDriverData = () => {
            driverService.value.getDrivers().then((data) => {
                if(!data.errorResponse) {
                    drivers.value = data;
                } else {
                    toast.add({severity:'warn', summary: 'Error ' + data.status, detail: data.errorResponse, life: 5000});
                }
            });
        }

        const openDriverFormDialog = (formtype, driverIndex) => {
            showDriverFormDialog.value = true
            form_type.value = formtype
            
            if(formtype == 'add'){
                dialogHeader.value = 'Add Driver'
            } else {
                dialogHeader.value = 'Update Driver'
                store.dispatch('driver_updateform', drivers.value[driverIndex])
            }
        }

        const confirmDeleteDriver = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.driver_name +'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const driver_id = data.id
                    driverService.value.deleteDriver(driver_id).then((data) => {
                        if(!data.errorResponse){
                            drivers.value = drivers.value.filter(val => val.id !== driver_id);
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});
                        }
                    })
                }
            });
        }

        const setFilterTransCompanyDropdown = () => {
            driverService.value.getAllCompanyName().then(data => {
                filterTransCompanyDropdown.value.push({id: 0, company_name: 'Any'})
                for(const item of data) {
                    filterTransCompanyDropdown.value = [...filterTransCompanyDropdown.value, {id: item.id, company_name: item.company_name}]
                }

                selectedTransCompanyFilter.value = filterTransCompanyDropdown.value[0]
            });
        }

        const filterTransCompany = () => {
            const transCompanyFilter = selectedTransCompanyFilter.value

            if(transCompanyFilter.id == 0){
                getDriverData()
            } else {
                driverService.value.filterCompany(transCompanyFilter).then(data => {
                    if(!data.errorResponse) {
                        drivers.value = data
                    }
                })
            }
        }

        const clearFilter = () => {
            getDriverData()
            selectedTransCompanyFilter.value = filterTransCompanyDropdown.value[0]
        }

        return {
            can,
            drivers,
            selectedDriver,
            filters,
            getDriverData,

            dialogHeader,
            form_type,
            showDriverFormDialog,
            openDriverFormDialog,
            confirmDeleteDriver,

            filterTransCompanyDropdown,
            selectedTransCompanyFilter,

            filterTransCompany,
            clearFilter,
        }
    },
    components: {
        DriverForm,
    }
}
</script>

<style lang="scss" scoped>
.driver-list .card {
    height: 620px;
}
</style>