import axios from '../axios.instance'

export default class DriverService {
    async getDrivers(){
        try {
            const res = await axios.get('/driver')
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async addDriver(data){
        try {
            if(data != ''){
                const res = await axios.post('/driver', {data})
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async updateDriver(driver_id, data) {
        try {
            if(data != '') {
                const res = await axios.put('/driver/'+driver_id, {data})
                return res.data;
            }
        } catch (error) {
            // const err = error.response
            return error.response.data
        }
    }

    async deleteDriver(driver_id){
        try {
            if(driver_id != 0) {
                const res = await axios.delete('/driver/'+driver_id)
                return res.data;
            }
        } catch (error) {
            return error.response.data
        }
    }

    async filterCompany(transCompanyFilter) {
        try {
            const res = await axios.post('/driver/filter-company', {transCompanyFilter});
            return res.data;
        } catch (error) {
            return error.response.data
        }
    }

    async getAllCompanyName() {
        try {
            const res = await axios.get('/driver/getcompanyname')
            return res.data;            
        } catch (error) {
            return error.response.data
        }
    }
}